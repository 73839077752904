import React, { Component } from 'react';
import { get, flowRight } from 'lodash';
import PropTypes from 'prop-types';

import {
  SECTION_CATEGORY,
  isLayoutProGallery,
  createPageUrl,
} from '@wix/communities-blog-client-common';
import AnimatedLoader from '../../../common/components/animated-loader';
import AppLoaded from '../../../common/components/app-loaded';
import withComponents from '../../../common/components/components-provider/with-components';
import { EmptyLayout } from '../../../common/components/layout';
import Page from '../../../common/components/page';
import { connect } from '../../../common/components/runtime-context';
import Pagination from '../../../common/containers/pagination';
import withAuth from '../../../common/hoc/with-auth';
import withLayoutProps from '../../../common/hoc/with-layout-props';
import withPermissions from '../../../common/hoc/with-permissions';
import withTranslate from '../../../common/hoc/with-translate';
import { isCreatePostButtonVisible } from '../../../common/selectors/blog-menu-button-selectors';
import { isFeedAdapterEnabled } from '../../../common/selectors/experiment-selectors';
import {
  isExcludePostContentSupported,
  isPostContentRequired,
} from '../../../common/selectors/layout-selectors';
import {
  getSortedCategoryPosts,
  getPostsByPage,
} from '../../../common/selectors/post-selectors';
import { getHashtagUrl } from '../../../common/services/getHashtagUrl';
import { handleNavigation } from '../../../common/services/navigation';
import {
  getIsMobile,
  getViewMode,
  isSite,
} from '../../../common/store/basic-params/basic-params-selectors';
import { getIsHashtagPostsLoaded } from '../../../common/store/is-loaded/is-loaded-selectors';
import { getIsHashtagPostsLoading } from '../../../common/store/is-loading/is-loading-selectors';
import { getSectionUrl } from '../../../common/store/topology/topology-selectors';
import PostList from '../../components/post-list';
import withPaginationSettings from '../../hoc/with-pagination-settings';

const Layout = EmptyLayout;

export class HashtagPage extends Component {
  state = {
    isAnimated: !this.props.allPosts.length,
  };

  componentWillUnmount() {
    this.props.setIsLoaded('hashtag-posts', null, true);
  }

  componentDidUpdate({ isAuthenticated, isBlocked, pageSize, excludeContent }) {
    const { hashtag } = this.props;
    if (
      this.props.isAuthenticated !== isAuthenticated ||
      this.props.isBlocked !== isBlocked ||
      this.props.pageSize !== pageSize ||
      (excludeContent && !this.props.excludeContent)
    ) {
      this.fetchPosts(hashtag);
    }
  }

  fetchPosts(hashtag, page = this.props.page) {
    if (this.props.isBlocked) {
      return;
    }

    return this.props.fetchHashtagPosts(hashtag, page);
  }

  loadMore = (page) => this.fetchPosts(this.props.hashtag, page);

  renderPosts() {
    const {
      currentPagePosts,
      allPosts,
      entityCount,
      showCreatePostAction,
      page,
      layoutType,
      showPagination,
      isHashtagLoading,
      isHashtagLoaded,
      PostListProGallery,
    } = this.props;

    if (isLayoutProGallery(layoutType)) {
      return (
        <PostListProGallery
          pageStart={page + 1}
          entityCount={entityCount}
          loadMore={showPagination ? undefined : this.loadMore}
          allPosts={showPagination ? currentPagePosts : allPosts}
          currentPagePosts={currentPagePosts}
          isLoading={isHashtagLoading}
          showCreatePostAction={showCreatePostAction}
          section={SECTION_CATEGORY}
        />
      );
    }

    return (
      <PostList
        pageStart={page + 1}
        layoutType={layoutType}
        entityCount={entityCount}
        loadMore={showPagination ? undefined : this.loadMore}
        allPosts={showPagination ? currentPagePosts : allPosts}
        currentPagePosts={currentPagePosts}
        isLoading={isHashtagLoading}
        isLoaded={isHashtagLoaded}
        showCreatePostAction={showCreatePostAction}
      />
    );
  }

  renderPagination() {
    const { entityCount, pageSize, page, hashtagUrl, isSite, showPagination } =
      this.props;

    return (
      <div
        data-hook="hashtag-pagination-container"
        style={{ display: showPagination ? 'block' : 'none' }}
      >
        <Pagination
          page={page}
          pageSize={pageSize}
          entityCount={entityCount}
          createPageUrl={(page) => createPageUrl(page, hashtagUrl)}
          handleNavigation={handleNavigation('hashtag', isSite)}
          showPagination={showPagination}
        />
      </div>
    );
  }

  render() {
    const { allPosts, isHashtagLoading, isHashtagLoaded } = this.props;

    const isLoading = isHashtagLoading && !isHashtagLoaded;
    return (
      <Page noSpacing>
        <Layout>
          <AnimatedLoader
            isLoading={isLoading && !allPosts.length}
            isAnimated={this.state.isAnimated}
          >
            {this.renderPosts()}
          </AnimatedLoader>
          {!isLoading && this.renderPagination()}
          {isHashtagLoaded && <AppLoaded />}
        </Layout>
      </Page>
    );
  }
}

HashtagPage.propTypes = {
  tagId: PropTypes.string.isRequired,
  tagUrl: PropTypes.string.isRequired,
  tagPath: PropTypes.string.isRequired,
  fetchTagPosts: PropTypes.func.isRequired,
  setIsLoaded: PropTypes.func.isRequired,
  allPosts: PropTypes.array,
  currentPagePosts: PropTypes.array,
  isAuthenticated: PropTypes.bool,
  isBlocked: PropTypes.bool,
  params: PropTypes.object,
  t: PropTypes.func,
  isHashtagLoading: PropTypes.bool,
  isHashtagLoaded: PropTypes.bool,
  isSite: PropTypes.bool,
  showCreatePostAction: PropTypes.bool.isRequired,
  layoutType: PropTypes.number.isRequired,
  excludeContent: PropTypes.bool,
  pageSize: PropTypes.number,
  entityCount: PropTypes.number,
  showPagination: PropTypes.bool,
  PostListProGallery: PropTypes.node.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  const hashtag = get(ownProps, ['params', 'hashtag']);

  const sectionUrl = getSectionUrl(state);

  const showCreatePostAction =
    getIsMobile(state) && isCreatePostButtonVisible(state, ownProps.canSee);

  return {
    hashtag,
    hashtagUrl: getHashtagUrl(sectionUrl, hashtag),
    allPosts: hashtag ? getSortedCategoryPosts(state) : [],
    currentPagePosts: hashtag ? getPostsByPage(state, ownProps.page) : [],
    isHashtagLoading: getIsHashtagPostsLoading(state),
    isHashtagLoaded: getIsHashtagPostsLoaded(state),
    isSite: isSite(state),
    showCreatePostAction,
    excludeContent: isExcludePostContentSupported(state),
    setIsLoaded: actions.setIsLoaded,
    fetchHashtagPosts: isFeedAdapterEnabled(state, getViewMode(state))
      ? (hashtag, page) =>
          actions.fetchHashtagPostsWithAdapterPromisified({
            page,
            includeContent: isPostContentRequired(state),
            hashtagOptions: { hashtag },
          })
      : actions.fetchHashtagPostsPromisified,
  };
};

export default flowRight(
  withPermissions,
  withComponents,
  withPaginationSettings(SECTION_CATEGORY),
  withLayoutProps(),
  withAuth,
  withTranslate,
  connect(mapRuntimeToProps),
)(HashtagPage);
