import React from 'react';
import { pick } from 'lodash';
import PropTypes from 'prop-types';
import App from '../../../common/components/app';
import CategoriesLink from '../../../common/components/categories-link';
import CategoryDesktopHeader from '../../../common/components/category-desktop-header';
import CategoryMobileHeader from '../../../common/components/category-mobile-header';
import LoginScreen from '../../../common/components/login-screen';
import PopoverRoot from '../../../common/components/popovers/popover-root';
import { connect } from '../../../common/components/runtime-context';
import NotFoundPage from '../../../common/containers/not-found-page';
import { getCurrentMatch } from '../../../common/router/router-selectors';
import SearchDesktopHeader from '../../../search/containers/search-header/search-desktop-header';
import SearchMobileHeader from '../../../search/containers/search-header/search-mobile-header';
import SearchResultsPage from '../../../search/containers/search-results-page/search-results-page';
import MessageRoot from '../../components/messages/message-root';
import ModalRoot from '../../components/modals/modal-root';
import {
  ROUTE_HOME,
  ROUTE_404,
  ROUTE_LOGIN,
  ROUTE_CATEGORIES,
  ROUTE_CATEGORY,
  ROUTE_SEARCH,
  ROUTE_ARCHIVE,
  ROUTE_TAGS,
  ROUTE_HASHTAG,
} from '../../constants/routes';

import ArchivePage from '../archive-page';
import CategoryPage from '../category-page';
import FeedPage from '../feed-page';
import HashtagPage from '../hashtag-page';
import TagsPage from '../tags-page';

const WrapComponent = (Component) => (props) =>
  (
    <App
      main={<Component params={props.params} />}
      modalRoot={<ModalRoot />}
      messageRoot={<MessageRoot />}
      popoverRoot={<PopoverRoot />}
      mobileHeader={<CategoryMobileHeader />}
      mobileMenu={<CategoriesLink />}
      desktopHeader={<CategoryDesktopHeader />}
    />
  );

const ROUTE_COMPONENT_MAP = {
  [ROUTE_404]: WrapComponent(NotFoundPage),
  [ROUTE_LOGIN]: WrapComponent(LoginScreen),
  [ROUTE_CATEGORIES]: WrapComponent(CategoryPage),
  [ROUTE_CATEGORY]: WrapComponent(CategoryPage),
  [ROUTE_HASHTAG]: WrapComponent(HashtagPage),
  [ROUTE_ARCHIVE]: WrapComponent(ArchivePage),
  [ROUTE_TAGS]: WrapComponent(TagsPage),
  [ROUTE_SEARCH]: (props) => (
    <App
      main={<SearchResultsPage params={props.params} />}
      // modalRoot={<ModalRoot />}
      // messageRoot={<MessageRoot />}
      mobileHeader={<SearchMobileHeader params={props.params} />}
      desktopHeader={<SearchDesktopHeader params={props.params} />}
    />
  ),
  [ROUTE_HOME]: WrapComponent(FeedPage),
};

const Router = ({ route, ...rest }) => {
  const Component = ROUTE_COMPONENT_MAP[route]
    ? ROUTE_COMPONENT_MAP[route]
    : () => <div>Route "{route}" - is not implemented</div>;
  return <Component {...rest} />;
};

Router.propTypes = {
  route: PropTypes.string,
  pathname: PropTypes.string,
  sectionUrl: PropTypes.string,
  params: PropTypes.object,
};

const mapRuntimeToProps = (state) => ({
  ...pick(getCurrentMatch(state), [
    'route',
    'pathname',
    'sectionUrl',
    'params',
  ]),
});

export default connect(mapRuntimeToProps)(Router);
